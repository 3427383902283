/*
 *
 * This file contains the vuex root
 *
 */
import { Context } from "@nuxt/types";
import { prop } from "ramda";
import { ActionTree, GetterTree, MutationTree } from "vuex/types";
import { getClientId } from "~/utils/analytics";
import { AppCookieNames } from "~/constants/ecomApi";

// @todo look to remove this store index or merge in requires into other stores
export interface AppState {
  domain: string;
  gaServerClientMeasurementId?: null | string;
  stockSites: {
    collection: {
      name: string;
      id: boolean | string;
    };
  };
  pageLoadingIndicatorEnabled: boolean;
}

export const state = (): AppState => ({
  domain: "",
  stockSites: {
    collection: {
      name: "",
      id: false,
    },
  },
  pageLoadingIndicatorEnabled: true,
});

export type RootState = AppState;

export const mutations: MutationTree<RootState> = {
  setDomain(state: AppState, domain: string) {
    state.domain = domain;
  },
  setCollectionSite: (state, data) => {
    state.stockSites.collection = data;
  },
  setGaServerClientMeasurementId: (state, clientId) => {
    state.gaServerClientMeasurementId = clientId;
  },
  setPageLoadingIndicator: (state, val) => {
    state.pageLoadingIndicatorEnabled = val;
  },
};

export const getters: GetterTree<RootState, RootState> = {
  domain: prop("domain"),
  getCollectionSite: (state) => state.stockSites.collection,
  getFreePoint: () => null,
  gaServerClientMeasurementId: (state) =>
    state?.gaServerClientMeasurementId ?? null,
  isCustomerLike: (_state, _getters, _rootState, rootGetters) => {
    return (
      rootGetters["auth/isAuthenticated"] ||
      rootGetters["guest/isCustomerGuest"]
    );
  },
  pageLoadingIndicatorEnabled: (state) => state.pageLoadingIndicatorEnabled,
};

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit({ dispatch, commit }, context: Context) {
    commit(
      "setDomain",
      context.req.headers.referer ?? `${context.req.headers.host}/`
    );

    await dispatch("ecomApi/fetchEcomApiAccessToken", context);

    await dispatch("guest/setGuestUserFromCookie", context);

    const customerJwt = context.$cookies.get(AppCookieNames.CustomerJWT);
    const guestJwt =
      context.$cookies.get(AppCookieNames.GuestCheckoutSession) ?? null;
    const trolley = context.$cookies.get(AppCookieNames.Trolley)?.trolleyId;

    // Hydrate
    commit("auth/setJwt", customerJwt, { root: true });
    commit("guest/setGuestCheckoutSession", guestJwt);
    commit("trolley/setTrolleyIdData", trolley);

    // TODO: how we organise the store and what and which dispatch calls are run
    await dispatch("locale/changeLocaleContent", context);

    await dispatch("locale/fetchDepartmentsHierarchy", context);

    commit("branch/setPostcodeApiKey", context.$config.locationKey, {
      root: true,
    });
    commit("searchBar/setSearchApi", context.$config.searchApi, { root: true });
    commit("search/setSearchApi", context.$config.searchApi, { root: true });

    // Feature flags
    dispatch("featureFlags/parseFeatureFlags", {}, { root: true });

    await Promise.all([
      dispatch("locale/setSsrPageUrl", context.req),
      dispatch("auth/setAuthenticatedUser", context),
      dispatch("countrySetting/fetchCountrySettings", context.i18n.locale),
    ]);
  },

  async retrieveGaServerClientMeasurementId({ commit }, $config) {
    commit("setGaServerClientMeasurementId", await getClientId($config));
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
