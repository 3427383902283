import { NuxtAxiosInstance } from "@nuxtjs/axios";
import {
  compose,
  filter,
  head,
  identity,
  ifElse,
  isNil,
  map,
  sortBy,
} from "ramda";
import {
  getTaxonomiesTopLevel,
  getTaxonomyHierarchy,
  getTaxonomyById,
} from "./api/taxonomies.api";
import type {
  SeeAlsoItem,
  BloomreachSeeAlsoItem,
  TaxonomyData,
  TaxonomyDataApi,
  BloomreachTaxonomyItem,
  BloomreachTaxonomyFacetInfo,
} from "./taxonomies.d";
import { Department } from "~/components/layout/header/components/Departments.d";
import { handleError } from "~/services/helpers";
import { get } from "~/utils/lru-cache";
import { BloomreachFolderPaths } from "~/services/api/bloomreach.api";
import { fetchBrDocument } from "~/services/bloomreach.service";

type FilterHiddenAndSort = (department: Department[]) => Department[];
const filterHiddenAndSort: FilterHiddenAndSort = compose<
  Department[][],
  Department[],
  Department[],
  Department[]
>(
  map(
    ifElse(
      (d) => d?.children?.length > 0,
      (d) => ({ ...d, children: filterHiddenAndSort(d.children) }),
      identity
    )
  ),
  sortBy((d) => d?.order ?? 0),
  filter<Department>((d) => (d?.menu ?? 1) === 1)
);

export const fetchTopLevelDepartmentsHierarchy = async (
  $axios: NuxtAxiosInstance
): Promise<Department[] | null> =>
  await get(`fetchTopLevelDepartmentsHierarchy`, async () => {
    try {
      return filterHiddenAndSort(await getTaxonomiesTopLevel($axios));
    } catch (error) {
      handleError(error);
      return null;
    }
  });

export const fetchDepartmentsHierarchy = async (
  $axios: NuxtAxiosInstance
): Promise<Department[] | null> =>
  await get(`fetchDepartmentsHierarchy`, async () => {
    try {
      return filterHiddenAndSort(await getTaxonomyHierarchy($axios));
    } catch (error) {
      handleError(error);
      return null;
    }
  });

const mapTaxonomyData = (data: TaxonomyDataApi): TaxonomyData => ({
  ancestors: !isNil(data.ancestors)
    ? data.ancestors.map((ancestor) => mapTaxonomyData(ancestor))
    : [],
  menu: data.menu,
  order: data.order,
  parentId: data.parent_id,
  taxonomy: data.taxonomy,
  children: !isNil(data.children)
    ? data.children.map((child) => {
        return {
          menu: child.menu,
          order: child.order,
          parentId: child.parent_id,
          taxonomy: {
            slug: child.taxonomy.slug,
            id: child.taxonomy.id,
            name: child.taxonomy.name,
            image: child.taxonomy.image,
          },
          children: !isNil(child?.children)
            ? child?.children.map((subChild) => {
                return {
                  menu: subChild.menu,
                  order: subChild.order,
                  parentId: subChild.parent_id,
                  taxonomy: {
                    slug: subChild.taxonomy.slug,
                    id: subChild.taxonomy.id,
                    name: subChild.taxonomy.name,
                  },
                };
              })
            : [],
        };
      })
    : [],
});

export const fetchTaxonomyById = async (
  $axios: NuxtAxiosInstance,
  catId: number
): Promise<any> =>
  await get(`fetchTaxonomy.${catId}`, async () => {
    try {
      return await getTaxonomyById($axios, catId).then((result) => {
        if (result?.data) {
          return head([
            mapTaxonomyData(result.data) as Department,
          ]) as TaxonomyData;
        }
      });
    } catch (error) {
      return handleError(error);
    }
  });

export const getSeeAlsoItems = async (
  $axios: NuxtAxiosInstance,
  seeAlsoItems: SeeAlsoItem[]
): Promise<SeeAlsoItem[]> => {
  const removeInvalidItems = (item: SeeAlsoItem) => {
    const { href, name, id } = item;
    return (
      !(href && !name && !id) ||
      !(!href && name && !id) ||
      !(!href && !name && !id)
    );
  };

  const generateHref = (path: String) =>
    path.startsWith("/") ? path : `/${path}`;

  const processItem = async (item: SeeAlsoItem): Promise<SeeAlsoItem> => {
    const { href, name, id } = item;

    if (href && id && !name) {
      const {
        taxonomy: { name },
      } = await fetchTaxonomyById($axios, Number(id));
      return { href: generateHref(href), name };
    }

    if (href && name) {
      return { href: generateHref(href), name };
    }

    if (!href && id) {
      const { taxonomy, ancestors } = await fetchTaxonomyById(
        $axios,
        Number(id)
      );
      const href = !ancestors.length
        ? `/${taxonomy.slug}/c${taxonomy.id}`
        : `/${ancestors[0].taxonomy.slug}/${taxonomy.slug}/c${taxonomy.id}`;
      return { href, name: name || taxonomy.name };
    }

    return { href: "", name: "" };
  };

  return await Promise.all(
    seeAlsoItems.filter(removeInvalidItems).map(processItem)
  );
};

export const fetchBloomreachTaxonomy = async (
  $axios: NuxtAxiosInstance,
  isBrandPage: boolean,
  id: string,
  brxmContentEndpoint: string
): Promise<any> => {
  const bloomreachTaxonomyDocument = await fetchBrDocument(
    BloomreachFolderPaths[isBrandPage ? "Brands" : "Categories"],
    id,
    brxmContentEndpoint
  );

  const {
    defaultTaxonomyInfo: {
      taxonomyDescription: { value: taxonomyDescription } = { value: "" },
      taxonomyTitle = "",
      taxonomyMetaTitle = "",
      taxonomyMetaDescription = "",
      taxonomySeeAlso = [],
    } = {},
    taxonomyFacetInfo = [],
  } = bloomreachTaxonomyDocument || {};

  const mapTaxonomySeeAlsoItems = (
    items: BloomreachSeeAlsoItem[]
  ): SeeAlsoItem[] => {
    if (!items.length) return [];
    return items.map(
      (item): SeeAlsoItem => ({
        href: item.linkSlug,
        name: item.linkName,
        id: item.taxonomyId.replace(/^c/, ""),
      })
    );
  };

  const createTaxonomyItem = ({
    name,
    description,
    metaTitle,
    metaDescription,
    seeAlsoItems,
    facetQuery = undefined,
  }: BloomreachTaxonomyItem) => {
    return {
      facetQuery,
      name,
      description,
      metaTitle,
      metaDescription,
      seeAlsoItems,
    };
  };

  const bloomreachTaxonomy = async () => {
    if (isBrandPage && !bloomreachTaxonomyDocument) return;

    // Fetch the default taxonomy see also items
    const defaultSeeAlsoItems: SeeAlsoItem[] = await getSeeAlsoItems(
      $axios,
      mapTaxonomySeeAlsoItems(taxonomySeeAlso)
    );

    // Default bloomreach taxonomy items
    const defaultTaxonomy = createTaxonomyItem({
      name: taxonomyTitle,
      description: taxonomyDescription,
      metaTitle: taxonomyMetaTitle,
      metaDescription: taxonomyMetaDescription,
      seeAlsoItems: defaultSeeAlsoItems,
    });

    // Fetch the bloomreach facet taxonomy items
    const facetTaxonomy = await (async () => {
      const facetTaxonomyArray = await Promise.all(
        taxonomyFacetInfo.map(
          async (taxonomyItem: BloomreachTaxonomyFacetInfo) => {
            const facetSeeAlsoItems = await getSeeAlsoItems(
              $axios,
              mapTaxonomySeeAlsoItems(
                taxonomyItem.facetTaxonomyInfo?.taxonomySeeAlso ?? []
              )
            );

            return createTaxonomyItem({
              name: taxonomyItem.facetTaxonomyInfo?.taxonomyTitle,
              description: taxonomyItem.facetTaxonomyInfo?.taxonomyDescription,
              metaTitle: taxonomyItem.facetTaxonomyInfo?.taxonomyMetaTitle,
              metaDescription:
                taxonomyItem.facetTaxonomyInfo?.taxonomyMetaDescription,
              seeAlsoItems: facetSeeAlsoItems.length
                ? facetSeeAlsoItems
                : defaultSeeAlsoItems,
              facetQuery: taxonomyItem.facetString,
            });
          }
        )
      );

      // Convert array to object with facetQuery as the key
      return facetTaxonomyArray.reduce((acc, item) => {
        acc[item.facetQuery] = item;
        return acc;
      }, {});
    })();

    return {
      defaultTaxonomy,
      facetTaxonomy,
    };
  };

  return await bloomreachTaxonomy();
};

export default { fetchDepartmentsHierarchy, fetchTaxonomyById };
