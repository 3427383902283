import { indexBy, last, prop } from "ramda";
import { mapGetters } from "vuex";
import { AppCookieNames } from "~/constants/ecomApi";
import { getTaxonomyBreadcrumbs } from "~/lib/taxonomies/breadcrumbItems";

export default {
  computed: {
    ...mapGetters("search", [
      "getActiveFilters",
      "getQuery",
      "getSearchData",
      "getAttributionToken",
      "getSearchType",
      "filterWasAdded",
    ]),
    ...mapGetters("branch", ["selectedBranchId"]),
    ...mapGetters("stock", ["hasStockForSite", "hasStockForDelivery"]),
    ...mapGetters("trolley", [
      "trolleyTotalRaw",
      "trolleyItemsCount",
      "trolleyData",
      "lastAddedProduct",
      "lastProductCategoryTree",
    ]),
    ...mapGetters("taxonomy", [
      "getTaxonomy",
      "getTopParentSlug",
      "getTaxonomyAncestors",
    ]),
    ...mapGetters("auth", ["getUser", "isAuthenticated"]),
    ...mapGetters("featureFlags", ["getFeatureFlags"]),
  },
  mounted() {
    const eventsToConsume = [
      ...(this.$store.getters["tracking/getEventToConsume"] ?? []),
    ];

    // Consume all events that have been pushed before the subscriber has initiated
    if (eventsToConsume.length) {
      eventsToConsume.forEach((item) => {
        if (typeof item === "object") {
          this[item.type](item.data);
        } else {
          this[item]();
        }
      });
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      // There is a new event to be tracked
      if (
        mutation.type === "tracking/addTrackingEvent" &&
        state.tracking.eventsToConsume.length > 0
      ) {
        if (typeof mutation.payload === "object") {
          this[mutation.payload.type](mutation.payload.data);
        } else {
          this[mutation.payload]();
        }
      }

      if (mutation.type === "trolley/setLastTrolleyUpdateProductCodes") {
        const trolleyLines = this.$store.getters["trolley/trolleyLines"];
        const lastAddedProduct = trolleyLines.slice(-1)[0];
        if (lastAddedProduct) {
          this.trackTrolleyAdd(trolleyLines, lastAddedProduct);
        }
      }

      if (mutation.type === "search/updateActiveFilters") {
        if (this.filterWasAdded) {
          this.trackActiveFilters();
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    trackPageLoaded() {
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackLastEvent() {
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSearchPage() {
      this.$gtm.push({
        event: "Search",
        eventCategory: "Search Actions",
        eventAction: "Searched Term",
        eventValue: this.getQuery,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSearchSuggestion() {
      this.$gtm.push({
        event: "SearchSuggestion",
        inputValue: "inputValue",
        suggestionValue: "suggestionValue",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSearchSuggestionClick(suggestion) {
      let suggestionValue;

      if (suggestion.type === "category") {
        suggestionValue = suggestion.item?.value;
      } else if (suggestion.type === "query") {
        suggestionValue = suggestion.item?.query;
      } else if (suggestion.type === "product") {
        suggestionValue = suggestion.item?.pid;
      }

      this.$gtm.push({
        event: "SearchSuggestionClicked",
        type: suggestion.type,
        suggestionValue,
        suggestionItem: suggestion.item,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");

      this.$dd.addAction("searchSuggestionClicked", {
        type: suggestion.type,
        suggestionValue,
        suggestionItem: suggestion.item,
      });
    },
    trackSearchInput(searchValue) {
      this.$gtm.push({
        event: "SearchInput",
        searchInputValue: searchValue,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSearchRedirected(isRedirected) {
      this.$gtm.push({
        event: "searchRedirect",
        searchRedirected: isRedirected,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackTrolleyUpdate({ event, qty }) {
      this.$gtm.push({
        event: event === "add" ? "addToCart" : "removeFromCart",
        ecommerce: {
          currencyCode: "GBP",
          [event]: {
            products: [
              {
                name: this.lastAddedProduct.product.fullName,
                id: this.lastAddedProduct.product.code,
                price: this.lastAddedProduct.product.prices.raw.gross,
                brand: this.lastAddedProduct.product.brand,
                category: this.lastProductCategoryTree,
                quantity: qty,
              },
            ],
          },
        },
        removeOnNewPage: true,
      });
      if (event === "add") {
        // clear the cloud retail variable in the data layer so it doesn't retain old data...
        this.$gtm.push({ cloud_retail: null });
        this.$gtm.push({
          event: "cloud_retail",
          removeOnNewPage: true,
          cloud_retail: {
            eventType: "add-to-cart",
            ...this.getCommonCloudRetailData(),
            productDetails: [
              {
                product: { id: this.lastAddedProduct.product.code },
                quantity: qty,
              },
            ],
          },
        });
      }
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackTrolleyAdd(trolleyLines, lastAddedProduct) {
      this.$gtm.push({
        event: "addToCart",
        ecommerce: {
          currencyCode: "GBP",
          add: {
            products: [
              {
                name: lastAddedProduct.product.fullName,
                id: lastAddedProduct.product.code,
                price: lastAddedProduct.product.prices.raw.gross,
                brand: lastAddedProduct.product.brand,
                category: this.lastProductCategoryTree,
                quantity: lastAddedProduct.quantity,
              },
            ],
          },
        },
        currentTrolley: trolleyLines,
        removeOnNewPage: true,
      });
      // clear the cloud retail variable in the data layer so it doesn't retain old data...
      this.$gtm.push({ cloud_retail: null });
      this.$gtm.push({
        event: "cloud_retail",
        removeOnNewPage: true,
        cloud_retail: {
          eventType: "add-to-cart",
          ...this.getCommonCloudRetailData(),
          productDetails: [
            {
              product: { id: lastAddedProduct.product.code },
              quantity: lastAddedProduct.quantity,
            },
          ],
        },
      });
      this.$store.commit("tracking/trackingEventConsumed");

      this.$dd.addAction("addToCart", {
        product: {
          id: lastAddedProduct.product.code,
          name: lastAddedProduct.product.fullName,
          price: lastAddedProduct.product.prices.raw.gross,
          brand: lastAddedProduct.product.brand,
          category: this.lastProductCategoryTree,
          quantity: lastAddedProduct.quantity,
        },
      });
    },
    trackSaveForLater() {
      this.$gtm.push({
        event: "SaveForLaterClick",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackVariations() {
      this.$gtm.push({
        event: "VariationsClick",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackModalClose() {
      this.$gtm.push({
        event: "ModalClosed",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackBannersOnPage(count) {
      this.$gtm.push({
        event: "bannersOnPage",
        removeOnNewPage: true,
        count,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackBannerView(banner) {
      this.$gtm.push({
        event: "bannerViewed",
        removeOnNewPage: true,
        banner: banner.index,
        promotionName: banner.href,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackBannerClick(banner) {
      this.$gtm.push({
        event: "bannerClicked",
        removeOnNewPage: true,
        banner: banner.index,
        promotionName: banner.href,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSmartBannerVisible() {
      this.$gtm.push({
        event: "smartBannerVisible",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSmartBannerClick() {
      this.$gtm.push({
        event: "smartBannerClick",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSmartBannerClose() {
      this.$gtm.push({
        event: "smartBannerClose",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackCatalogueClick() {
      this.$gtm.push({
        event: "CatalogueClicked",
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackProductClick(product) {
      this.$gtm.push({
        event: "productClick",
        click: {
          products: {
            name: product.title,
            id: product.pid,
            price: product.price,
            brand: product.brand,
            category: product.category,
            position: product.index,
          },
        },
        removeOnNewPage: true,
      });

      if (product.category === "search") {
        this.$gtm.push({
          event: "Search",
          eventCategory: "Search Actions",
          eventAction: "Click Position",
          eventValue: product.index,
          removeOnNewPage: true,
        });
      }
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackPromoClick(promoUrl) {
      this.$gtm.push({
        event: "promotionClick",
        ecommerce: {
          promoClick: {
            promotions: {
              id: this.getPromoAttr(promoUrl, "promo_id"),
              creative: this.getPromoAttr(promoUrl, "promo_creative"),
              name: this.getPromoAttr(promoUrl, "promo_name"),
              position: this.getPromoAttr(promoUrl, "promo_position"),
            },
          },
        },
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    pathwaysAndRecsViewPixel(trackingData) {
      this.$gtm.push({
        event: "pathwaysAndRecsView",
        pathwaysAndRecViewData: trackingData,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");

      this.$dd.addAction("recsView", { trackingData });
    },
    pathwaysAndRecsClickPixel(trackingData) {
      this.$gtm.push({
        event: "pathwaysAndRecsClick",
        pathwaysAndRecClickData: trackingData,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");

      this.$dd.addAction("recsClick", { trackingData });
    },
    promoAndRecsClick(tracking) {
      this.$gtm.push({
        event: "promoAndRecClick",
        promoAndRecClickData: tracking,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    productPageLoaded(productData) {
      this.$gtm.push({
        event: "pdpPage",
        ...productData,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    productPageView(productData) {
      const {
        brand,
        code: id,
        fullName,
        prices,
        slug,
      } = productData.productInfo;

      const topLevelCategory = last(productData.productInfo.taxonomies[0]).name;
      const categoryTree = productData.productInfo.taxonomies[0]
        .map((category) => category.name)
        .join("/");

      this.$gtm.push({
        event: "detailView",
        ecommerce: {
          detail: {
            actionField: {
              list: topLevelCategory,
            },
            products: [
              {
                brand,
                category: categoryTree,
                id,
                name: fullName,
                price: prices.raw.gross,
                url: `${this.$store.getters.domain}${slug}/${id}`,
                collectionInStock: this.hasStockForSite(
                  id,
                  this.selectedBranchId
                ),
                collectionIsAvailable: this.hasStockForSite(
                  id,
                  this.selectedBranchId
                ),
                deliveryInStock: this.hasStockForDelivery(id),
                deliveryIsAvailable: this.hasStockForDelivery(id),
              },
            ],
          },
          impressions: {
            products: productData.productImpressions,
          },
        },
        removeOnNewPage: true,
      });
      // clear the cloud retail variable in the data layer so it doesn't retain old data...
      this.$gtm.push({ cloud_retail: null });
      this.$gtm.push({
        event: "cloud_retail",
        removeOnNewPage: true,
        cloud_retail: {
          eventType: "detail-page-view",
          ...this.getCommonCloudRetailData(),
          productDetails: [{ product: { id } }],
        },
      });
      this.$store.commit("tracking/trackingEventConsumed");

      this.$dd.addAction("detailPageView", {
        product: {
          id,
          name: fullName,
          brand,
          price: prices.raw.gross,
          url: `${this.$store.getters.domain}${slug}/${id}`,
          deliveryInStock: this.hasStockForDelivery(id),
          collectionInStock: this.hasStockForSite(id, this.selectedBranchId),
        },
      });
    },
    getPromoAttr(promoUrl, type) {
      return promoUrl
        .split("&")
        .filter((val) => val.split("=")[0] === type)
        .join()
        .split("=")[1];
    },

    trackAddToCartClick(tracking) {
      const { type, productName } = tracking;
      this.$gtm.push({
        event: type === "delivery" ? "DeliveryClick" : "CollectionClick",
        removeOnNewPage: true,
      });

      if (this.$route.name === "topslug-productId") {
        this.$gtm.push({
          event: "add to cart - pdp",
          method: type === "delivery" ? "delivery" : "collection",
          removeOnNewPage: true,
        });
        this.$gtm.push({
          event: "pdp_add_to_cart",
          item_id: productName,
          method: type === "delivery" ? "delivery" : "collection",
          removeOnNewPage: true,
        });
      } else {
        this.$gtm.push({
          event: "add to cart - quick add",
          method: type === "delivery" ? "delivery" : "collection",
          removeOnNewPage: true,
        });
        this.$gtm.push({
          event: "quick_add_to_cart",
          item_id: productName,
          method: type === "delivery" ? "delivery" : "collection",
          removeOnNewPage: true,
        });
      }

      this.$store.commit("tracking/trackingEventConsumed");
    },

    trackProductListingPage() {
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackActiveFilters() {
      const filters = this.getActiveFilters
        .map((filter) => `${filter.name} - ${filter.value}`)
        .sort((a, b) => a.localeCompare(b))
        .join(" | ");

      this.$gtm.push({
        event: "Search",
        eventCategory: "Search Actions",
        eventAction: "Add Filter",
        eventValue: filters,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackSearchOrCategory(data) {
      this.$gtm.push({
        event: "view_item_list",
        ecommerce: {
          items: data.products.map((product, index) => {
            return {
              item_name: product.title,
              item_id: product.pid,
              price: product.price,
              item_brand: product?.brand ?? null,
              item_category: data?.category ?? null,
              item_list_name: data.type,
              item_list_id: data.type,
              index,
              quantity: "1",
              collectionInStock: this.hasStockForSite(
                product.pid,
                this.selectedBranchId
              ),
              collectionIsAvailable: this.hasStockForSite(
                product.pid,
                this.selectedBranchId
              ),
              deliveryInStock: this.hasStockForDelivery(product.pid),
              deliveryIsAvailable: this.hasStockForDelivery(product.pid),
            };
          }),
        },
      });
      // clear the cloud retail variable in the data layer so it doesn't retain old data...
      const { crs_filter, crs_order_by, crs_offset, attribution_token } =
        this.getSearchData?.metadata || {};
      const searchType = this.getSearchType;
      this.$gtm.push({ cloud_retail: null });
      this.$gtm.push({
        event: "cloud_retail",
        removeOnNewPage: true,
        cloud_retail: {
          eventType: "search",
          ...this.getCommonCloudRetailData(),
          ...(attribution_token ? { attributionToken: attribution_token } : {}),
          ...(crs_filter ? { filter: crs_filter } : {}),
          ...(crs_order_by ? { orderBy: crs_order_by } : {}),
          ...(crs_offset ? { offset: crs_offset } : {}),
          ...(searchType !== "category" ? { searchQuery: this.getQuery } : {}),
          ...(searchType === "category"
            ? {
                pageCategories:
                  this.getSearchData?.metadata?.page_categories ||
                  (this.getSearchData?.facetCounts?.facetFields?.category ?? [])
                    .filter(
                      (category) =>
                        this.getQuery === category.cat_id && category.tree_path
                    )
                    .map((category) =>
                      category.tree_path
                        .split("/")
                        .map((path) => path.split(",")[1] || "")
                        .filter((path) => !["", "Home"].includes(path))
                        .join(" > ")
                    ),
              }
            : {}),
          productDetails: (this.getSearchData?.response?.docs || []).map(
            (product) => ({
              product: { id: product.pid },
            })
          ),
        },
      });
      this.$store.commit("tracking/trackingEventConsumed");

      this.$dd.setGlobalContextProperty("lastSearchTerm", this.getQuery);

      this.$dd.addAction("search", {
        searchType: this.getSearchType === "category" ? "category" : "search",
        searchQuery: this.getQuery,
        products: indexBy(
          prop("id"),
          (this.getSearchData?.response?.docs || []).map((product) => ({
            id: product.pid,
            product: {
              id: product.pid,
              brand: product?.brand ?? null,
              category: data?.category ?? null,
              breadcrumb: product?.slug ?? "",
              price: product.price,
            },
          }))
        ),
        productCodes: (this.getSearchData?.response?.docs || []).map(
          (product) => product.pid
        ),
        filter: this.getSearchData?.metadata?.crs_filter || null,
        orderBy: this.getSearchData?.metadata?.crs_order_by || null,
        offset: this.getSearchData?.metadata?.crs_offset || null,
        resultCount: this.getSearchData?.response?.numFound || null,
      });
    },
    trackPageView(data) {
      const { to } = data;

      this.$gtm.push({
        event: "nuxtRoute",
        pageType: "PageView",
        pageUrl: to.fullPath,
        routeName: to.name,
        removeOnNewPage: true,
      });

      this.pushCloudRetailPageViewEvents(data);

      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackMetaData() {
      this.$gtm.push({
        event: "metaData",
        trolleyTotal: this.trolleyTotalRaw ?? 0,
        trolleyQuantity: this.trolleyItemsCount ?? 0,
        trolleyCount: this.trolleyTotalRaw ?? 0,
        promotionsApplied: !!this.trolleyData?.applied_promo_codes.length,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    checkoutConfirmation(tracking) {
      const sellableItems = (tracking.products?.items || []).filter(
        (item) => item?.product?.sellable || false
      );

      this.$gtm.push({
        event: "purchase",
        ecommerce: {
          purchase: {
            actionField: {
              id: tracking.products?.id ?? "",
              fullOrderId: tracking.products?.id ?? "",
              affiliation: "Toolstation",
              isApp: false,
            },
            products: tracking.products.items.map((item) => {
              return {
                id: item.product?.code ?? "",
                name: item.product?.name ?? "",
                sku: item.product?.code ?? "",
                price: item.prices?.product_raw ?? null,
                quantity: item?.item_quantity ?? null,
              };
            }),
          },
        },
        removeOnNewPage: true,
      });

      this.$gtm.push(
        {
          event: "TrackingOrderConfirmPageLoaded",
          currency: "GBP",
          items: tracking.products.items.map((item) => {
            return {
              name: item.product?.name ?? "",
              code: item.product?.code ?? "",
              breadcrumb: item.product?.slug ?? "",
              qty: item?.item_quantity ?? null,
            };
          }),
          orderId: tracking.products?.id ?? "",
          fullOrderId: tracking.products?.id ?? "",
          done: true,
          revenue: tracking.products?.order_totals?.raw ?? "",
          customerHasCompany: tracking?.customerHasCompany ?? null,
          removeOnNewPage: true,
        },
        tracking.products
      );

      this.$gtm.push({ cloud_retail: null });
      this.$gtm.push({
        event: "cloud_retail",
        removeOnNewPage: true,
        cloud_retail: {
          eventType: "purchase-complete",
          ...this.getCommonCloudRetailData(),
          productDetails: tracking.products?.items
            ?.filter((item) => item.product?.sellable)
            ?.map((item) => {
              return {
                product: { id: item.product?.code },
                quantity: item?.item_quantity ?? null,
              };
            }),
          purchaseTransaction: {
            revenue: tracking.products?.order_totals?.raw,
            currencyCode: "GBP",
          },
        },
      });

      this.$dd.addAction("purchaseComplete", {
        orderId: tracking.products?.id ?? "",
        orderTotal: tracking.products?.order_totals?.raw,
        lineCount: sellableItems.length || 0,
        itemCount:
          sellableItems.reduce(
            (count, item) => count + item?.item_quantity || 0,
            0
          ) || 0,
        productCodes: sellableItems.map((item) => item.product?.code),
        products: indexBy(
          prop("id"),
          sellableItems.map((item) => {
            return {
              id: item.product?.code,
              product: {
                id: item.product?.code,
                brand: item.product?.brand ?? null,
                price: item.product.price,
              },
              quantity: item?.item_quantity ?? null,
            };
          })
        ),
      });

      this.$store.commit("tracking/trackingEventConsumed");
    },
    trackMonetateExperimentDecision(impression) {
      if (impression.experienceId && impression.variantLabel) {
        this.$gtm.push({ monetateImpression: null });
        this.$gtm.push({
          event: "monetate_experiment_decision",
          monetateImpression: impression,
          removeOnNewPage: true,
        });
        this.$store.commit("tracking/trackingEventConsumed");
      }
    },
    trackStockLoaded({ stock }) {
      const stockAvailabilityTracking = Array.isArray(stock)
        ? stock.reduce(
            (acc, { productCode, stockQty, siteId }) => {
              acc.numberOfResults++;
              acc.productCodes = [
                ...new Set([...acc.productCodes, productCode]),
              ];
              acc.numberOfProducts = acc.productCodes.length;
              acc.products[productCode] = acc.products[productCode] || {};

              if (siteId === "WW" && stockQty > 0) {
                acc.availableForDelivery++;
                acc.products[productCode].DELIVERY = stockQty;
              }
              if (siteId !== "WW" && stockQty > 0) {
                acc.availableForCollection++;
                acc.products[productCode].COLLECTION = stockQty;
                acc.products[productCode][siteId] = stockQty;
              }

              return acc;
            },
            {
              products: {},
              productCodes: [],
              numberOfProducts: 0,
              numberOfResults: 0,
              availableForDelivery: 0,
              availableForCollection: 0,
              selectedBranchId: this.selectedBranchId,
            }
          )
        : {};

      this.$dd.setGlobalContextProperty("availableStock", {
        percentageAvailableForCollection:
          (stockAvailabilityTracking.availableForCollection /
            stockAvailabilityTracking.productCodes.length) *
          100,
        percentageAvailableForDelivery:
          (stockAvailabilityTracking.availableForDelivery /
            stockAvailabilityTracking.productCodes.length) *
          100,
        ...stockAvailabilityTracking,
      });
      this.$dd.addAction("stockLoaded");
      this.$gtm.push({ stock_loaded: null });
      this.$gtm.push({
        event: "stock_loaded",
        removeOnNewPage: true,
        stock_loaded: stock,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    pushCloudRetailPageViewEvents(data) {
      const { to } = data;

      if (to.fullPath === "/") {
        // this is a home page view...
        this.$gtm.push({ cloud_retail: null });
        this.$gtm.push({
          event: "cloud_retail",
          removeOnNewPage: true,
          cloud_retail: {
            eventType: "home-page-view",
            ...this.getCommonCloudRetailData(),
          },
        });
      } else if (to.fullPath === "/trolley") {
        // this is a trolley page view...
        this.$gtm.push({ cloud_retail: null });
        this.$gtm.push({
          event: "cloud_retail",
          removeOnNewPage: true,
          cloud_retail: {
            eventType: "shopping-cart-page-view",
            ...this.getCommonCloudRetailData(),
            productDetails: this.trolleyData?.lines.map((item) => ({
              product: { id: item.product.code },
              quantity: item.quantity,
            })),
          },
        });
      } else if (to.name.includes("topslug")) {
        // this is a category page view...
        const categoryBreadcrumb = getTaxonomyBreadcrumbs(
          this.getTaxonomy,
          this.getTopParentSlug,
          this.getTaxonomyAncestors
        )
          ?.map((breadcrumb) => breadcrumb.title)
          ?.join(" > ");

        if (categoryBreadcrumb) {
          this.$gtm.push({ cloud_retail: null });
          this.$gtm.push({
            event: "cloud_retail",
            removeOnNewPage: true,
            cloud_retail: {
              eventType: "category-page-view",
              ...this.getCommonCloudRetailData(),
              pageCategories: [categoryBreadcrumb],
            },
          });
        }
      }
    },
    trackOutOfStockMessageVisible(products) {
      this.$gtm.push({
        event: "outOfStockMessageVisible",
        products,
        removeOnNewPage: true,
      });
      this.$store.commit("tracking/trackingEventConsumed");
    },
    getCommonCloudRetailData() {
      return {
        visitorId: this.$cookies.get(AppCookieNames.TsSessionId),
        ...(this.getCrsExperimentIds() || {}),
        ...(this.isAuthenticated && this.getUser.id
          ? { userInfo: { userId: this.getUser.id } }
          : {}),
      };
    },
    getCrsExperimentIds() {
      const { browseExperimentId, searchExperimentId } = this.getFeatureFlags;

      return browseExperimentId || searchExperimentId
        ? {
            experimentIds: [
              ...(browseExperimentId ? [`Browse_${browseExperimentId}`] : []),
              ...(searchExperimentId ? [`Search_${searchExperimentId}`] : []),
            ],
          }
        : {};
    },
  },
};
